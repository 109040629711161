<template>
  <q-card>
    <q-expansion-item
      v-model="isOpen"
      expand-separator
      :label="$t('Article') + ': ' + ((model && model.name) || $t('No name'))"
    >
      <form-builder :schema="schema" />

      <div class="q-pa-sm text-right">
        <q-btn
          color="negative"
          :label="$t('Delete')"
          no-caps
          @click="deleteHelpArticle"
        />
      </div>
    </q-expansion-item>
  </q-card>
</template>

<script>
export default {
  name: 'HelpArticleSection',
  props: {
    model: {
      type: Object,
      default () {
        return {
          state: 'active',
          type: 'simple',
          name: '',
          content: '',
          // conditions: []
        }
      }
    }
  },
  data () {
    return {
      isOpen: true,
      types: ['simple', 'todo']
    }
  },
  computed: {
    schema () {
      return {
        isLoading: !this.model,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                value: this.model && this.model.name,
                label: this.$t('Name'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                field: 'name',
                required: true,
                onChange: name => {
                  this.update({ name })
                }
              },
              {
                type: 'select',
                label: this.$t('Type'),
                value: this.model && this.model.type,
                customLabel: (row) => {
                  if (!row) {
                    return ''
                  }

                  const text = row[0].toUpperCase() + row.slice(1).toLowerCase()
                  return this.$t(text)
                },
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                required: true,
                field: 'type',
                options: this.types,
                onChange: (type) => {
                  this.update({ type })
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.model && this.model.priority,
                label: this.$t('Priority'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                field: 'priority',
                required: true,
                onChange: priority => {
                  this.update({ priority })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.model && this.model.intro,
                label: this.$t('Intro'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                field: 'intro',
                required: true,
                onChange: intro => {
                  this.update({ intro })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.model && this.model.content,
                label: this.$t('Content'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                field: 'content',
                required: true,
                onChange: content => {
                  this.update({ content })
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    deleteHelpArticle () {
      this.$emit('update', { ...this.model, state: 'inactive' })
    },
    update (update) {
      this.$emit('update', { ...this.model, ...update })
    }
  }
}
</script>
