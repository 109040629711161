<template>
  <div>
    <action-header
      :is-loading="!helpSection"
      :actions-model="headerModel"
      :page="page"
    />

    <q-card class="q-ma-md">
      <q-legend :label="$t('Section')" />

      <form-builder :schema="schema" />
    </q-card>

    <q-card class="q-ma-md">
      <q-legend :label="$t('Articles')" />

      <div
        v-for="helpArticle in activeHelpArticles"
        :key="helpArticle.id"
        class="q-pa-sm"
      >
        <help-article-section
          :model="helpArticle"
          @update="updateHelpArticle"
        />
      </div>

      <div
        v-if="isHelpArticleLoading"
        class="row justify-center items-center text-center q-my-md text-subtitle1"
        style="min-height: 200px;"
      >
        <q-spinner
          color="primary"
          size="4rem"
        />
      </div>

      <div
        v-else-if="activeHelpArticles.length <= 0"
        class="text-subtitle1 text-center"
      >
        {{ $t('Please add articles') }}
      </div>

      <div
        v-if="!isHelpArticleLoading"
        class="q-pa-sm"
      >
        <q-btn
          color="light-blue-9"
          :label="$t('Add')"
          icon="add"
          no-caps
          @click="addHelpArticle"
        />
      </div>
    </q-card>

    <sticky-bottom-header
      :is-loading="isSaveLoading"
      :is-active="hasChange"
      @back="handleDiscard"
      @save="save"
    />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import ActionHeader from '../../components/action-header/ActionHeader.vue'
import HelpArticleSection from '../../components/sections/HelpArticleSection.vue'

// Utils
import _ from 'lodash'
import { difference } from '../../helpers/request-helpers'

export default {
  name: 'HelpSection',
  components: {
    ActionHeader,
    HelpArticleSection
  },
  data () {
    return {
      isHelpArticleLoading: false,
      isSaveLoading: false,
      hasChange: false,
      helpArticles: [],
      originalHelpArticles: []
    }
  },
  computed: {
    ...mapGetters([
      'helpSection'
    ]),
    activeHelpArticles () {
      return this.helpArticles.filter(x => x.state === 'active')
    },
    page () {
      return {
        id: this.helpSection && this.helpSection.id,
        name: this.helpSection && this.helpSection.id
          ? this.$t('Help section')
          : this.$t('New help section')
      }
    },
    headerModel () {
      if (!this.helpSection) {
        return []
      }

      return [
        {
          section: 'BackAction',
          className: 'q-pr-sm hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'col-sm-3 mobile-title',
          options: [
            {
              id: 'title',
              type: 'text',
              className: 'text-white',
              value: this.helpSection && this.helpSection.id
                ? this.$t('Help section') + ':' + this.helpSection.id
                : this.$t('New help section')
            }
          ]
        }
      ]
    },
    schema () {
      return {
        isLoading: !this.helpSection,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                value: this.helpSection && this.helpSection.name,
                label: this.$t('Section Name'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                disabled: this.isSaveLoading,
                field: 'name',
                required: true,
                onChange: name => {
                  this.updateHelpSection({ name })
                  this.hasChange = true
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.helpSection && this.helpSection.route,
                label: this.$t('Route'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                disabled: this.isSaveLoading,
                field: 'route',
                required: true,
                onChange: route => {
                  this.updateHelpSection({ route })
                  this.hasChange = true
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.helpSection && this.helpSection.priority,
                label: this.$t('Priority'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                field: 'priority',
                required: true,
                onChange: priority => {
                  this.updateHelpSection({ priority })
                  this.hasChange = true
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.helpSection && this.helpSection.description,
                label: this.$t('Description'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                disabled: this.isSaveLoading,
                field: 'description',
                required: true,
                onChange: description => {
                  this.updateHelpSection({ description })
                  this.hasChange = true
                }
              }
            ]
          }
        ]
      }
    }
  },
  mounted () {
    if (this.$route.params.id) {
      if (!this.helpSection) {
        this.loadHelpSection(this.$route.params.id)
      }

      this.loadHelpArticles(this.$route.params.id)

      return
    }

    this.setNewHelpSection()
  },
  methods: {
    ...mapActions([
      'loadHelpSection',
      'saveHelpSection'
    ]),
    ...mapMutations([
      'updateHelpSection',
      'setNewHelpSection',
      'setHelpSection'
    ]),
    loadHelpArticles (section) {
      const query = {
        page: 1,
        per_page: 250,
        filter: [
          { type: 'eq', field: 'state', value: 'active' },
          { type: 'eq', field: 'section', value: section }
        ],
        'order-by': [
          { type: 'field', field: 'priority', direction: 'desc' }
        ]
      }

      this.isHelpArticleLoading = true
      return this.$service.helpArticle.getAll(query)
        .then(({ items }) => {
          this.helpArticles = items
          this.originalHelpArticles = _.cloneDeep(items)
          return items
        })
        .finally(() => {
          this.isHelpArticleLoading = false
        })
    },
    addHelpArticle () {
      const helpArticle = {
        id: Math.floor(Math.random() * (9999999999) + 1),
        state: 'active',
        type: 'simple',
        name: '',
        content: '',
        // conditions: []
      }

      this.helpArticles = [helpArticle, ...this.helpArticles]
      this.hasChange = true
      return helpArticle
    },
    removeHelpArticle (id) {
      if (this.$route.params.id) {
        const originalHelpArticle = this.originalHelpArticles.find(x => x.id == id)

        if (!originalHelpArticle) {
          this.helpArticles = this.helpArticles.filter(x => x.id != id)
        } else {
          this.helpArticles = this.helpArticles.map(x => {
            return x.id == id
              ? { ...x, state: 'inactive' }
              : x
          })
        }
      } else {
        this.helpArticles = this.helpArticles.filter(x => x.id != id)
      }

      this.hasChange = true
      return this.helpArticles
    },
    updateHelpArticle (helpArticle) {
      this.helpArticles = this.helpArticles.map(x => {
        return x.id == helpArticle.id
          ? helpArticle
          : x
      })

      this.hasChange = true
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    save () {
      this.isSaveLoading = true

      return this.saveHelpSection()
        .then(helpSection => {
          this.setHelpSection(helpSection)
          return this.saveHelpArticles(this.helpArticles, this.originalHelpArticles, [])
        })
        .then(articles => {
          this.helpArticles = articles.filter(x => x.state !== 'inactive')
          this.originalHelpArticles = _.cloneDeep(this.helpArticles)

          if (!this.$route.params.id) {
            this.handleBack()
          } else {
            this.hasChange = false
          }
        })
        .finally(() => {
          this.isSaveLoading = false
        })
    },
    saveHelpArticle (item, orItem) {
      if (orItem) {
        const data = difference({ ...item, _embedded: undefined }, { ...orItem, _embedded: undefined })

        if (Object.keys(data).length === 0) {
        return Promise.resolve(item)
      }

        return this.$service.helpArticle.save(data, item.id)
      }

      const data = {
        ...item,
        id: undefined,
        _embedded: undefined,
        section: this.helpSection.id
      }

      return this.$service.helpArticle.save(data)
    },
    saveHelpArticles (helpArticles, orHelpArticles, results = []) {
      if (helpArticles.length <= 0) {
        return Promise.resolve(results)
      }

      const helpArticle = helpArticles[0]
      const orHelpArticle = orHelpArticles.find(x => x.id == helpArticle.id)

      return this.saveHelpArticle(helpArticle, orHelpArticle)
        .then(item => {
          results.push(item)
          return this.saveHelpArticles(helpArticles.slice(1), orHelpArticles, results)
        })
    }
  }
}
</script>
